*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  min-height: 100vh;
  font-size: 1.4rem;
  line-height: 1.7;
  box-sizing: border-box;
  font-family: "Inter Regular";
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 4rem;
  font-family: "Inter Bold";
  line-height: 4.7;
  letter-spacing: 0.2px;
}

h2 {
  font-size: 2.4rem;
  font-family: "Inter Bold";
  line-height: 2.9;
  letter-spacing: 0.2px;
}

h3 {
  font-size: 2rem;
  font-family: "Inter Bold";
  line-height: 2.2;
  letter-spacing: 0.1px;
}

h4 {
  font-size: 1.8rem;
  font-family: "Inter Bold";
  line-height: 2.2;
  letter-spacing: 0.1px;
}

.layout--default {
  display: grid;
  grid-template-columns: max-content 1fr;
  background-color: var(--bg-color);
}
