@mixin media($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 600px) {
      @content;
    }
  }

  @if $breakpoint == tab-port {
    @media only screen and (min-width: 600px) and (max-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint == tab-land {
    @media only screen and (orientation: landscape) and (min-width: 600px) and (max-width: 1220px) {
      @content;
    }
  }

  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 1400px) {
      @content;
    }
  }
}
