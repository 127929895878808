@import "../../helpers/mixins";

.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  background-color: rgba(#414444, 0.6);
  transition: 0.4s all ease-out;
  z-index: 100;

  &__box {
    display: block;
    position: relative;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    width: 54rem;
    min-height: 6rem;
    max-height: 80vh;
    border-radius: 2rem;
    margin-top: 1.6rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-bottom: 2.4rem;
    background-color: var(--white-color);
    border: 0.2rem solid var(--white-color);
    color: #414444;
    animation: modal-fade-in 0.4s linear;
    overflow: hidden;

    @include media(phone) {
      max-height: fit-content;
      max-height: -moz-fit-content;
    }

    &__header {
      padding: 2.4rem;

      &__title {
        position: relative;
        text-align: center;
        width: 100%;
        margin-bottom: 2.4rem;

        &::before {
          content: "";
          position: absolute;
          display: block;
          height: 0.3rem;
          width: 9rem;
          left: 50%;
          bottom: 0;
          border: 0.1rem solid transparent;
          border-radius: 0.2rem;
          background-color: var(--primary-color);
          transform: translateX(-50%);
        }
      }

      &__close {
        cursor: pointer;
        position: absolute;
        top: 4.2rem;
        right: 3.2rem;
        border: none;
        background: none;
        font-size: 1.4rem;
        color: #414444;
        border: none;
      }

      &__back {
        cursor: pointer;
        position: absolute;
        top: 4.2rem;
        left: 3.2rem;
        border: none;
        background: none;
        font-size: 1.4rem;
        color: #414444;
        border: none;
      }
    }

    &__image {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      height: 20rem;
      border-radius: 0.4rem;

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    &__content {
      display: block;
      padding: 0 2.4rem 1.6rem 2.4rem;
      word-break: break-word;
      min-height: 6rem;
      max-height: 60vh;
      overflow-y: scroll;

      @include media(phone) {
        &.scroll {
          &::before,
          &::after {
            display: none !important;
          }
        }
      }

      &.scroll {
        &:before {
          content: "";
          display: flex;
          position: sticky;
          top: -0.675rem;
          left: 0;
          width: 100%;
          height: 2.4rem;
          margin-top: -2.4rem;
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0)
          );
          z-index: 10;
        }

        &:after {
          content: "";
          display: flex;
          position: sticky;
          bottom: -1.6rem;
          left: 0;
          width: 100%;
          height: 2.4rem;
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 1)
          );
          z-index: 10;
        }
      }
    }

    &__m {
      @extend .modal__box;
      width: 80rem;

      @include media(phone) {
        width: 90vw;
      }
    }

    &__l {
      @extend .modal__box;
      width: 88.5vw;

      @include media(phone) {
        width: 90vw;
      }
    }

    &__info {
      @extend .modal__box;
      height: 24rem;

      @include media(phone) {
        width: 90vw;
      }
    }
  }
}

@keyframes modal-fade-in {
  0% {
    opacity: 0;
    transform: translate(-50%, -100%);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes modal-fade-in-alert {
  0% {
    opacity: 0;
    transform: translate(-50%, -100%);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
