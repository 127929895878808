.button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter SemiBold";
  font-size: 1.4rem;
  line-height: 1.7;
  padding: 0.8rem 6rem;
  background-color: var(--white-color);
  border: 0.1rem solid var(--gray-color);
  border-radius: 0.4rem;
  transition: 0.4s all ease-in-out;

  .icon {
    margin-right: 0.8rem;
    height: 1.2em;
    width: auto;
  }

  &__primary {
    @extend .button;
    background-color: var(--primary-color);
    color: var(--white-color);
  }

  &__outline {
    @extend .button;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    font-family: "Inter Regular";
  }

  &__outline:hover {
    color: var(--primary-color);
    background-color: #f5f5f5;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}
