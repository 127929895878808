@import "../../helpers/reset";

:root {
  --primary-color: #c8d400;
  --white-color: #ffffff;
  --gray-color: #e0e0e0;
  --bg-color: #fafafc;
  --dim-color: #637381;
  --brand-danger: #e21010;
}
