@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-display: swap;
  font-family: Inter Bold;
  font-weight: normal;
  src: url("./assets/fonts/Inter-Bold.ttf");
}

@font-face {
  font-display: swap;
  font-family: Inter SemiBold;
  font-weight: normal;
  src: url("./assets/fonts/Inter-SemiBold.ttf");
}

@font-face {
  font-display: swap;
  font-family: Inter Medium;
  font-weight: normal;
  src: url("./assets/fonts/Inter-Medium.ttf");
}

@font-face {
  font-display: swap;
  font-family: Inter Regular;
  font-weight: normal;
  src: url("./assets/fonts/Inter-Regular.ttf");
}

@font-face {
  font-display: swap;
  font-family: Inter Light;
  font-weight: normal;
  src: url("./assets/fonts/Inter-Light.ttf");
}

@font-face {
  font-display: swap;
  font-family: Inter Thin;
  font-weight: normal;
  src: url("./assets/fonts/Inter-Thin.ttf");
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafc;
}

@keyframes progress-bar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.animate-progress-bar {
  animation: progress-bar 10s linear forwards;
}
