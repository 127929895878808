.home {
  position: relative;
  padding: 2rem;
  animation: fade-in 0.2s ease-out;
  height: 100vh;
  overflow-y: scroll;

  &__heading {
    display: flex;
    flex-direction: column;
    margin-top: 2.4rem;

    &--main {
      display: grid;
      grid-template-columns: 36rem max-content 1fr 24rem;
      column-gap: 0.8rem;
    }
  }

  &__table {
    position: relative;
    margin-top: 2.4rem;
    width: 100%;
  }
}

@keyframes progress-bar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.animate-progress-bar {
  animation: progress-bar 10s linear forwards;
}

.door .active-card-message {
  margin-top: 8px; // oder der gewünschte Abstand
}
