.preloader {
  display: block;
  position: relative;
  transition: 0.4s all ease-out;
  overflow-x: hidden;
  height: 2.4rem;
  margin-bottom: 1.6rem;

  &:after {
    content: "";
    background-color: #333;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: preloader infinite 2s;
    background: linear-gradient(
      to right,
      rgba(#414444, 0) 0%,
      rgba(#414444, 0.15) 30%,
      rgba(#414444, 0) 81%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
  }
}

@keyframes preloader {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
